<template>
  <MDBDropdown v-model="dropdown">
    <MDBDropdownToggle class="action" @click.stop.prevent="dropdown = !dropdown"
      >Action</MDBDropdownToggle
    >
    <MDBDropdownMenu>
      <MDBDropdownItem
        href="#"
        v-if="!asset.isTracked"
        @click.stop.prevent="toggleTrack(asset, true)"
        >Track this asset
      </MDBDropdownItem>
      <MDBDropdownItem
        href="#"
        v-if="asset.isTracked"
        @click.stop.prevent="toggleTrack(asset, false)"
        >Untrack this asset
      </MDBDropdownItem>
      <MDBDropdownItem
        href="#"
        class="text-danger"
        @click.stop.prevent="deleteAsset(asset.assetIdString)"
        >Delete</MDBDropdownItem
      >
    </MDBDropdownMenu>
  </MDBDropdown>
</template>
<script>
import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from "mdb-vue-ui-kit";
import { PutAssetTrackingStatus } from "../services/Assets/PutAssetTrackingStatus";
import { DeleteAsset } from "../services/Assets/DeleteAsset";
import { ref } from "vue";
export default {
  components: {
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
  },
  props: {
    asset: {
      type: Object,
    },
  },
  emits: ["toggleStatus", "deleteAsset"],
  setup(props, { emit }) {
    const dropdown = ref(false);
    const toggleTrack = async (asset, toggleValue) => {
      await PutAssetTrackingStatus(asset.assetIdString, {
        IsTracked: toggleValue,
      });
      emit("toggleStatus", asset.title);
    };
    const deleteAsset = async (assetId) => {
      await DeleteAsset(assetId);
      emit("deleteAsset", assetId);
    };
    return {
      ...props,
      dropdown,
      toggleTrack,
      deleteAsset,
    };
  },
};
</script>
<style scoped>
.action {
  background-color: var(--primary);
  border-color: var(--primary);
  color: white;
}
</style>
