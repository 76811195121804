import { HttpClient } from "../HttpClient";
/**
 * * Get Collection's Assets
 */
export const GetCollectionAssets = async ({
  collectionId,
  lastItem,
  searchString,
  isTracked,
}) => {
  try {
    const { data } = await HttpClient.get(
      `api/pexcollections/${collectionId}/assets`,
      {
        params: {
          ...(lastItem ? { maxId: lastItem } : {}),
          ...(searchString && searchString !== null
            ? { searchString: searchString }
            : {}),
          ...(isTracked !== null ? { isTracked: isTracked } : {}),
          count: 50,
        },
      }
    );
    return await Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};
