import { HttpClient } from "../HttpClient";
/**
 * * Get Collection
 */
export const GetCollection = async (collectionId) => {
    try {
        const { data } = await HttpClient.get(`api/pexcollections/${collectionId}`);
        return await Promise.resolve(data);
    } catch (err) {
        return Promise.reject(err);
    }
};
