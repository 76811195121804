<template>
    <LayoutNew>
      <MDBCard id="CollectionsAssetsComponent" fluid>
        <!-- asset details -->
        <MDBCardHeader class="p-4">
          <template v-if="isLoading">
            <div>
              <MDBSpinner />
            </div>
          </template>
          <template v-else>
            <MDBCardTitle tag="h1">{{ collectionInfo.title }}</MDBCardTitle>
            <MDBCardText class="d-flex" style="gap: 30px">
              <p class="mb-0">
                <strong>Reference ID:</strong> {{ collectionInfo.referenceId }}
              </p>
              <p class="mb-0">
                <strong>Created At:</strong>
                {{ parseDateLong(collectionInfo.createdAt) }}
              </p>
              <p class="mb-0">
                <strong>Updated At:</strong>
                {{ parseDateLong(collectionInfo.updatedAt) }}
              </p>
            </MDBCardText>
          </template>
        </MDBCardHeader>
        <!-- group buttons -->
        <MDBCardHeader class="p-4">
          <MDBInput placeholder="Search Assets" type="text" v-model="searchInput" inputGroup :formOutline="false">
            <template #prepend>
              <MDBDropdown v-model="filterDropdown">
                <MDBDropdownToggle color="primary" @click="filterDropdown = !filterDropdown" class="rounded-end-0">
                  <MDBIcon icon="filter" class="me-2"></MDBIcon>
                  {{ selectedFilter }}
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                  <MDBDropdownItem tag="div" style="cursor: pointer;" @click.prevent="selectedFilter = 'All'">
                    All
                  </MDBDropdownItem>
                  <MDBDropdownItem tag="div" style="cursor: pointer;" @click.prevent="selectedFilter = 'Tracked Only'">
                    Tracked Only
                  </MDBDropdownItem>
                  <MDBDropdownItem tag="div" style="cursor: pointer;" @click.prevent="selectedFilter = 'Untracked Only'">
                    Untracked Only
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </template>
            <MDBPopover v-model="exportToggle" tag="div">
              <template #reference>
                <MDBBtn class="shadow-none rounded-0" color="primary" tabindex="0" @click="exportToggle = !exportToggle">
                  <span>Export</span>
                  <MDBIcon icon="arrow-down" class="fas fa-fw" />
                </MDBBtn>
              </template>
              <template #body>
                <MDBSelect v-model:options="exportTypeList" v-model:selected="exportTypeSelected" class="mb-3" />
                <MDBAlert color="primary" static class="d-flex align-items-center p-auto">
                  Please note asset export is limited to 1 million
                  entries
                </MDBAlert>
                <MDBBtn class="m-0" size="sm" color="primary" @click.prevent="exportAssets">
                  Export
                </MDBBtn>
              </template>
            </MDBPopover>
            <MDBBtn class="shadow-none rounded-start-0" color="primary" @click="goToAsset">
              Add Asset(s)
            </MDBBtn>
          </MDBInput>
        </MDBCardHeader>
        <!-- table -->
        <MDBCardBody>
          <CollectionsAssetsComponentTable ref="tableRef" :searchString="searchInput" :isTracked="selectedFilter" />
        </MDBCardBody>
      </MDBCard>
      <!-- toast -->
      <MDBToast v-model="exportToast" :delay="2000" autohide position="top-right" appendToBody stacking width="350px"
        color="success" text="white" icon="fas fa-check fa-lg me-2">
        <template #title> Success </template>
        {{ collectionInfo.title }}'s Asset Exported Successfully
      </MDBToast>
    </LayoutNew>
  </template>
  
  <script setup>
  import {
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBInput,
    MDBBtn,
    MDBCardTitle,
    MDBCardText,
    MDBPopover,
    MDBAlert,
    MDBSelect,
    MDBIcon,
    MDBSpinner,
    MDBToast, MDBDropdown, MDBDropdownMenu, MDBDropdownItem, MDBDropdownToggle
  } from "mdb-vue-ui-kit";
  import LayoutNew from "@/views/v2/LayoutNew.vue";
  import { onMounted, ref, watch } from "vue";
  import { useRoute, useRouter } from "vue-router";
  import { GetCollection } from "@/services/Collections/GetCollection";
  import { parseDateLong } from "@/helpers/parseDate";
  import CollectionsAssetsComponentTable from "@/components/CollectionsAssetsComponentTable.vue";
  import { ExportCollectionAssets } from "@/services/Collections/ExportCollectionAssets";
  import { useTitle } from "@vueuse/core";
  
  const tableRef = ref(null);
  const collectionInfo = ref({});
  
  const searchInput = ref();
  watch(searchInput, (newVal) => {
    tableRef.value.setCollections({ searchString: newVal, isTracked: selectedFilter.value });
  });
  
  const route = useRoute();
  const isLoading = ref(false);
  onMounted(async () => {
    isLoading.value = true;
    collectionInfo.value = await GetCollection(route.params.id);
    useTitle(`${collectionInfo.value.title}'s Assets | CreatorShield`);
    isLoading.value = false;
  });
  
  const exportToggle = ref(false);
  const exportTypeList = ref([
    { text: "All Assets", value: null },
    { text: "Tracked Assets Only", value: true },
    { text: "Untracked Assets Only", value: false },
  ]);
  
  const exportTypeSelected = ref();
  const actionToggle = ref(false);
  const exportToast = ref(false);
  
  const router = useRouter();
  const goToAsset = () => {
    router.push({ name: "AssetsUpload" });
  };
  
  watch(actionToggle, (newVal) => {
    if (newVal && exportToggle.value) {
      exportToggle.value = false;
    }
  });
  
  const exportAssets = async () => {
    const isTracked = exportTypeSelected.value;
    const collectionId = route.params.id;
    const response = await ExportCollectionAssets(collectionId, isTracked);
  
    let fileURL = window.URL.createObjectURL(
      new Blob([response], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );
  
    let fileLink = document.createElement("a");
  
    fileLink.href = fileURL;
    fileLink.setAttribute(
      "download",
      `CreatorShield-Assets - ${collectionInfo.value.title}.xlsx`
    );
  
    document.body.appendChild(fileLink);
    fileLink.click();
    exportToggle.value = false;
    exportToast.value = true;
  };
  
  const filterDropdown = ref(false);
  const selectedFilter = ref("All");
  watch(selectedFilter, (newVal) => {
    tableRef.value.setCollections({ isTracked: newVal, searchString: searchInput.value });
  });
  </script>
  
  <style scoped>
  .btn-primary {
    background-color: var(--primary);
  }
  </style>
  