<template>
  <div class="overflow-auto" ref="el">
    <!-- toast -->
    <MDBTable striped hover class="align-middle">
      <thead class="table-dark">
        <tr>
          <th scope="col">Asset Name</th>
          <th scope="col">Created</th>
          <th scope="col">Updated</th>
          <th scope="col">Tracking</th>
          <th scope="col" width="100">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="isCollectionAssetsEmpty">
          <td colspan="5" class="text-center">No Assets Found</td>
        </tr>
        <tr class="row-action" v-for="(asset, index) in collectionAssetsList" :key="index"
          @click="goToAssets(asset.assetIdString)">
          <td>{{ asset.title }}</td>
          <td>{{ parseDateLong(asset.createdAt) }}</td>
          <td>{{ parseDateLong(asset.updatedAt) }}</td>
          <td>
            <div class="p-2" style="display: flex;gap: 5px;align-items: center;">
              <MDBBadge :color="asset.isTracked ? 'success' : 'danger'"
                class="translate-middle p-2 border border-light rounded-circle" dot
                :title="asset.isTracked ? 'Tracked' : 'Untracked'"
                style="position: relative;transform: unset !important;">
              </MDBBadge>
              <span>{{ asset.isTracked ? 'Tracked' : 'Untracked' }}</span>
            </div>
          </td>
          <td>
            <CollectionsAssetsComponentAction :asset="asset" @toggleStatus="toggleStatus" @deleteAsset="deleteAsset" />
          </td>
        </tr>
        <tr v-if="isLoading">
          <td colspan="5" class="text-center">
            <MDBSpinner />
          </td>
        </tr>
      </tbody>
    </MDBTable>
    <!-- toast -->
    <MDBToast v-model="collectionsAssetsComponentTableToast" :delay="2000" autohide position="top-right" appendToBody
      stacking width="350px" color="success" text="white" icon="fas fa-check fa-lg me-2">
      <template #title> Success </template>
      Asset <strong>{{ assetChangeStatusTitle }}</strong>'s {{ assetChangeStatusDescription }} Successfully
    </MDBToast>
  </div>
</template>

<script setup>
import { MDBTable, MDBSpinner, MDBBadge, MDBToast } from "mdb-vue-ui-kit";
import { useRoute, useRouter } from "vue-router";
import { useInfiniteScroll, useDebounceFn } from "@vueuse/core";
import { ref, onMounted, defineProps, defineExpose, nextTick } from "vue";
import { GetCollectionAssets } from "../services/Collections/GetCollectionAssets";
import { parseDateLong } from "../helpers/parseDate";
import CollectionsAssetsComponentAction from "./CollectionsAssetsComponentAction.vue";

const props = defineProps({ searchString: { type: String, default: null }, isTracked: { type: String, default: null } });

const router = useRouter();
const route = useRoute();
const collectionAssetsList = ref([]);
const isLoading = ref(false);
const isCollectionAssetsEmpty = ref(false);

const getCollectionAssets = async (lastItem) => {
  isLoading.value = true;
  const response = await GetCollectionAssets({
    collectionId: route.params.id,
    lastItem: lastItem,
    searchString: props.searchString,
    isTracked: setIsTracked(props.isTracked),
  });
  if (response.length === 0 && collectionAssetsList.value.length === 0) {
    isCollectionAssetsEmpty.value = response.length === 0;
  }
  return response;
};

const setIsTracked = (track) => {
  switch (track) {
    case "All":
      return null;
    case 'Tracked Only':
      return true;
    case 'Untracked Only':
      return false;
    default:
      return null;
  }
}

onMounted(async () => {
  collectionAssetsList.value = await getCollectionAssets();
  isLoading.value = false;
});

const setCollections = useDebounceFn(async ({ searchString, isTracked }) => {
  isLoading.value = true;
  collectionAssetsList.value = [];
  isCollectionAssetsEmpty.value = false;
  const response = await GetCollectionAssets({
    collectionId: route.params.id,
    searchString,
    isTracked: setIsTracked(isTracked),
  });
  if (response.length === 0 && collectionAssetsList.value.length === 0) {
    isCollectionAssetsEmpty.value = response.length === 0;
  }
  await nextTick();
  collectionAssetsList.value = response;
  isLoading.value = false;
}, 500);

defineExpose({ setCollections });
const el = ref();
const stopScrollLoad = ref(false);
useInfiniteScroll(
  el,
  async () => {
    if (
      isLoading.value ||
      stopScrollLoad.value ||
      collectionAssetsList.value.length === 0
    ) {
      return;
    }
    const currentArr = [...collectionAssetsList.value];
    const lastItem = currentArr.pop();
    const response = await getCollectionAssets(lastItem.assetIdString);
    if (response.length === 0) {
      stopScrollLoad.value = true;
      isLoading.value = false;
      return;
    }
    collectionAssetsList.value = collectionAssetsList.value.concat(response);
    isLoading.value = false;
  },
  { distance: 10 }
);

const goToAssets = (assetId) => {
  router.push({
    name: "CollectionsAssetsView",
    params: { id: route.params.id, assetId: assetId },
  });
};

const collectionsAssetsComponentTableToast = ref();
const assetChangeStatusTitle = ref("");
const assetChangeStatusDescription = ref("");
const toggleStatus = async (assetTitle) => {
  if (collectionsAssetsComponentTableToast.value) {
    collectionsAssetsComponentTableToast.value = false;
  }
  assetChangeStatusTitle.value = assetTitle;
  assetChangeStatusDescription.value = "Status Changed";
  collectionsAssetsComponentTableToast.value = true;
  collectionAssetsList.value = await getCollectionAssets();
};

const deleteAsset = async (assetTitle) => {
  if (collectionsAssetsComponentTableToast.value) {
    collectionsAssetsComponentTableToast.value = false;
  }
  assetChangeStatusTitle.value = assetTitle;
  assetChangeStatusDescription.value = "Deleted";
  collectionsAssetsComponentTableToast.value = true;
  collectionAssetsList.value = await getCollectionAssets();
};
</script>

<style scoped>

</style>