import { HttpClient } from "../HttpClient";
/**
 * * Post Asset File
 */
export const ExportCollectionAssets = async (collectionId, IsTracked) => {
    try {
        const { data } = await HttpClient.get(`api/pexcollections/${collectionId}/assets/excel`, {
            params: {
                IsTracked
            },
            responseType: "blob",
        });
        return await Promise.resolve(data);
    } catch (err) {
        return Promise.reject(err);
    }
};
